<template>
    <div>
        <ul>
            <slot></slot>
        </ul>
    </div>
</template>

<script setup></script>

<style lang="scss" scoped>
ul {
    padding: 0;
    margin: 0;
}
</style>
