import { useJsonld } from '#jsonld';

export function useStructuredProduct(product, price, new_availabilities) {
    const runtimeConfig = useRuntimeConfig();
    const main_url = `${runtimeConfig.public.domain}`;
    let products_json = [];
    if (product.structure === 'parent') {
        for (const child of new_availabilities.value) {
            for (const product_child of product.children) {
                if (product_child.id === child.id) {
                    let name = null;
                    if (product_child.custom_google_feed_title) {
                        name = product_child.custom_google_feed_title;
                    } else if (product.custom_google_feed_title) {
                        name = product.custom_google_feed_title;
                    } else {
                        name = [
                            product.title,
                            product_child.cut.title,
                            `${product_child.cut.weight}${product_child.cut.unit}`,
                        ]
                            .filter(Boolean)
                            .join(' ');
                    }

                    products_json.push({
                        '@context': 'https://schema.org/',

                        '@type': 'Product',

                        sku: product_child.upc,

                        image: [product?.images[0]?.src],

                        name: name,

                        description: product?.description,

                        brand: {
                            '@type': 'Brand',

                            name: 'LUMA Delikatessen',
                        },
                        offers: {
                            '@type': 'Offer',
                            availability: child.is_available_to_buy
                                ? 'https://schema.org/InStock'
                                : 'https://schema.org/OutOfStock',
                            itemCondition: 'https://schema.org/NewCondition',
                            url: `${main_url}${product.html_url}?variant=${product_child.id}`,
                            priceCurrency: child.currency,

                            price: child.action
                                ? child.incl_tax
                                : child.original_incl_tax,
                            // priceValidUntil today + 30 days
                            priceValidUntil: new Date(
                                Date.now() + 30 * 24 * 60 * 60 * 1000,
                            ).toISOString(),
                        },
                    });
                    break;
                }
            }
        }
    } else {
        const current_product_availability = new_availabilities.value[0]; // Only one Product in new_availabilities -> Standalone
        let name = null;
        if (product.custom_google_feed_title) {
            name = product.custom_google_feed_title;
        } else {
            name = `${product?.title}`;
        }
        products_json = {
            '@context': 'https://schema.org/',

            '@type': 'Product',

            sku: product?.upc,

            image: [product?.images[0]?.src],

            name: name,

            description: product?.description,

            brand: {
                '@type': 'Brand',

                name: 'LUMA Delikatessen',
            },
            offers: {
                '@type': 'Offer',
                availability: current_product_availability.is_available_to_buy
                    ? 'https://schema.org/InStock'
                    : 'https://schema.org/OutOfStock',
                itemCondition: 'https://schema.org/NewCondition',

                priceCurrency: current_product_availability?.currency,

                price: current_product_availability?.action
                    ? current_product_availability?.incl_tax
                    : current_product_availability?.original_incl_tax,
                // priceValidUntil today + 30 days
                priceValidUntil: new Date(
                    Date.now() + 30 * 24 * 60 * 60 * 1000,
                ).toISOString(),
            },
        };
    }
    useJsonld(products_json);
}
