<template>
    <div
        class="row justify-content-center spacer-navigation-overlap detail__product"
    >
        <div class="col-12 col-800-6 detail__image-column">
            <div
                class="image-slider"
                :class="
                    page?.product?.images?.length === 1
                        ? 'image-slider--single'
                        : ''
                "
            >
                <Slider
                    :wrapAround="true"
                    :showCurrent="true"
                    :slides="page?.product?.images"
                    :itemsToShow="1"
                    :breakpoints="{}"
                    v-if="page?.product?.images?.length > 1"
                >
                    <template #default="image">
                        <SlideProductImage
                            :image="{
                                ...image,
                                soldOut: !isAvailable,
                                offer: price?.action,
                                special,
                                organism: mold,
                                classic: chef,
                                new: newProduct,
                            }"
                            :productTitle="page?.product?.title"
                        />
                    </template>
                </Slider>
                <SlideProductImage
                    class="single-slide"
                    v-else
                    :image="{
                        ...(page?.product?.images[0] || ''),
                        soldOut: !isAvailable,
                        offer: price?.action,
                        special,
                        organism: mold,
                        classic: chef,
                        new: newProduct,
                    }"
                    :productTitle="page?.product?.title"
                />
                <hr v-if="page?.product?.images?.length > 1" />
            </div>
        </div>
        <div class="col-12 col-800-6">
            <PageBreadcrumbs :items="page?.breadcrumbs" class="breadcrumbs" />
            <div
                class="equal-base equal-base--tighter-bold-uppercase product__category"
            >
                {{ page.product.category }}
            </div>
            <h1
                class="scale-6 spacer-50-40"
                :id="slugifyString(page.product.title)"
            >
                {{ page.product.title }}
                <span class="scale-4 product__info">
                    {{ page.product.info }}
                </span>
            </h1>
            <Quotation
                :text="page.quotation"
                v-if="page.quotation"
                class="spacer-50-40"
            />
            <form class="spacer-60-50">
                <div class="spacer-40-30">
                    <div
                        class="equal-small-2 equal-small-2--bold-uppercase form__heading"
                        v-if="page.product.structure === 'parent'"
                    >
                        {{ t('detail.weight') }}
                    </div>

                    <FormRadioSelect
                        :choices="page.product.children"
                        name="variant"
                        v-model="selectedVariant"
                        v-if="page.product.structure === 'parent'"
                        :preselect="true"
                    >
                        <template #default="choice">
                            <FormRadioSelectItemCut
                                :choice="choice.cut"
                                @click="
                                    useTrackItemView(
                                        choice,
                                        choice.price,
                                        1,
                                        page,
                                    )
                                "
                            />
                        </template>
                    </FormRadioSelect>
                </div>
                <div class="spacer-40-30">
                    <div
                        class="equal-small-2 equal-small-2--bold-uppercase form__heading"
                    >
                        {{ t('detail.quantity') }}
                    </div>
                    <FormStepper
                        :min="1"
                        :max="num_available"
                        v-model="quantity"
                        @limit-reached="showAvailability = true"
                        :disabled="!canBeAdded"
                    />
                    <MessageRemovable
                        class="message--danger form__quantity-error"
                        :show="availabilityLoaded && showAvailability"
                        @remove="showAvailability = false"
                    >
                        <template #default>
                            <div v-html="availabilityMessage"></div>
                        </template>
                        <template #icon>
                            <IconsClose />
                        </template>
                    </MessageRemovable>
                    <MessageRemovable
                        class="message--danger form__quantity-error"
                        v-if="messages.length"
                        v-for="message in messages"
                    >
                        <template #default>
                            <div v-html="message"></div>
                        </template>
                        <template #icon>
                            <IconsClose />
                        </template>
                    </MessageRemovable>
                </div>
                <div v-if="availabilityLoaded">
                    <p
                        class="equal-base equal-base--tighter form__price-original"
                        v-if="price?.action"
                    >
                        {{ price?.currency }}
                        {{ price?.original_incl_tax }}
                    </p>
                    <p
                        class="scale-3 scale-3--bold form__price"
                        :class="{ 'form__price--offer': price?.action }"
                    >
                        {{ price?.currency }}
                        {{
                            price?.action
                                ? price?.incl_tax
                                : price?.original_incl_tax
                        }}
                    </p>
                </div>
                <div ref="intersector"></div>
                <FormButton
                    :label="t('product.picker.basket')"
                    @click="addToBasket"
                    :disabled="!canBeAdded"
                >
                    <IconsBasket />
                </FormButton>
            </form>
            <div>
                <ListsList class="spacer-20">
                    <ListsListItem
                        v-for="item in structuredInfo"
                        v-bind="item"
                    />
                </ListsList>
                <div
                    class="equal-base spacer-20"
                    v-html="page.product.description"
                ></div>
                <div id="product-details" class="spacer-80-60">
                    <LAccordionItem
                        v-for="(item, i) in page.product?.texts"
                        :title="item.label"
                        :content="item.text"
                        parentId="product-details"
                        :id="i.toString()"
                    >
                        <div v-html="item.text"></div>
                        <CmsMediaVideo
                            v-if="item?.video"
                            :source="item.video.source"
                            :center="false"
                        ></CmsMediaVideo>
                    </LAccordionItem>
                </div>
                <div v-if="page?.magazine_content?.items?.length">
                    <h3
                        class="scale-2 scale-2--bold spacer-40-30"
                        :id="slugifyString(t('detail.magazineContent'))"
                    >
                        {{ t('detail.magazineContent') }}
                    </h3>
                    <LoadMore
                        :page="page?.magazine_content?.load_more?.page"
                        :size="page?.magazine_content?.load_more?.size"
                        :pages="page?.magazine_content?.load_more?.pages"
                        :total="page?.magazine_content?.load_more?.total"
                        @next="recipePage++"
                    >
                        <template #default>
                            <RecipeTeaser
                                class="spacer-40-30"
                                v-for="item in page?.magazine_content.items.slice(
                                    0,
                                    recipePage *
                                        page?.magazine_content?.load_more?.size,
                                )"
                                v-bind="item"
                            />
                        </template>
                        <template
                            #next
                            v-if="
                                page?.magazine_content?.items.length >
                                recipePage *
                                    page?.magazine_content?.load_more?.size
                            "
                        >
                            <RecipeTeaser
                                class="spacer-40-30"
                                v-for="item in page?.magazine_content.items.slice(
                                    recipePage *
                                        page?.magazine_content?.load_more?.size,
                                )"
                                v-bind="item"
                            />
                        </template>
                    </LoadMore>
                </div>
                <ClientOnly>
                    <Transition name="slide-fade">
                        <div
                            v-if="true"
                            class="d-800-block"
                            :class="{ 'chart-float': true }"
                        >
                            <ProductShoppingCart
                                :message="
                                    basketStore.cartModalVal(
                                        'detail',
                                        'message',
                                    )
                                "
                                :payment="
                                    basketStore.cartModalVal(
                                        'detail',
                                        'message',
                                    )
                                "
                                :price-info="price"
                                :availabilities="availabilities"
                                :quantity="quantity"
                                id="detail"
                            />
                        </div>
                    </Transition>
                </ClientOnly>
            </div>
            <div ref="intersectorBottom"></div>
        </div>
    </div>
    <div v-if="page?.body.length > 0" class="spacer-footer">
        <h2 class="scale-5" :id="slugifyString(t('detail.similarProducts'))">
            {{ t('detail.similarProducts') }}
        </h2>
        <div class="spacer-footer">
            <CmsBody :page="page" />
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { ref, onMounted, computed } from 'vue';

import { useStructuredProduct } from '~/composables/structuredData/product';

import { useOscarClient } from '~/composables/useOscarClient';
import { useBasketStore } from '~/stores/basket';
import { useDetailStore } from '~/stores/detail';
import LAccordionItem from '@/components/accordion/AccordionItem.vue';
import {
    useTrackAddToCart,
    useTrackItemView,
} from '@/composables/gtm/useEcomTracking';
import { cleanMetaString } from '@/utils/cleanMeta';
import { slugifyString } from '~/utils/slugify';

const { t, locale } = useI18n();

const oscar = useOscarClient(locale.value);
const basketStore = useBasketStore();
const detailStore = useDetailStore();

const priceLoaded = ref(false);
const messages = ref([]);

const loading = ref(false);

function checkTag(code) {
    return (
        page?.value?.product?.tags.filter((x) => x.code === code).length == 1
    );
}

const recipePage = ref(1);

const newProduct = computed(() => {
    return checkTag('00');
});

const special = computed(() => {
    return checkTag('01');
});

const chef = computed(() => {
    return checkTag('02');
});

const mold = computed(() => {
    return checkTag('03');
});

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

if (page?.value?.product?.children) {
    page.value.product.children = page?.value?.product?.children.sort(
        (a, b) => {
            return a?.cut?.weight - b?.cut?.weight;
        },
    );
}
const selectedVariant = toRef(detailStore, 'variant');
selectedVariant.value =
    page?.value?.product?.structure === 'standalone'
        ? null
        : page?.value?.product?.children[0]?.id;

const new_availabilities = await useAsyncData(
    'availabilities',
    async () => {
        const current_product = page?.value?.product;
        const productIds = [];
        const new_availabilities_ = [];
        if (current_product.structure === 'standalone') {
            productIds.push(current_product.id);
        } else {
            current_product?.children?.forEach((child) =>
                productIds.push(child.id),
            );
        }
        for (const productId of productIds) {
            await oscar
                .getAvailability(productId, false)
                .then((res) => {
                    new_availabilities_.push({
                        id: productId,
                        ...res.data,
                    });
                })
                .catch((err) => console.log(err));
        }
        return new_availabilities_;
    },
    {
        server: true,
        lazy: false,
    },
);
const intersector = ref(null);
const quantity = toRef(detailStore, 'quantity');
const showAvailability = ref(false);

function handleIntersect(entries) {
    if (basketStore.cartModalVal('detail', 'message') === true) {
        basketStore.setCartModal('detail', 'show', true);
        return;
    }
    if (entries[0].boundingClientRect.top < 0 && !entries[0].isIntersecting) {
        basketStore.setCartModal('detail', 'show', true);
    } else {
        basketStore.setCartModal('detail', 'show', false);
    }
}

const {
    loaded: availabilityLoaded,
    availabilities,
    success,
    isAvailable,
} = useAvailability(ref(true), page.value.product);

const availabilityStructuredInfo = computed(() => {
    const res = page?.value?.structured_info;
    res.forEach((info) => {
        if (info.key === 'unit_price') {
            info.value = availabilities.value[0].price_per_unit;
        }
        if (info.key === 'shipping_date') {
            info.value = availabilities.value[0].next_shipping_date;
        }
    });
    return res;
});

const structuredInfo = computed(() => {
    return !availabilityLoaded || !availabilities.value.length
        ? page?.value?.structured_info
        : availabilityStructuredInfo.value;
});

function getSelectedChild() {
    for (let i = 0; i < page?.value?.product?.children.length; i++) {
        if (selectedVariant.value === page?.value?.product?.children[i].id) {
            return page?.value?.product?.children[i];
        }
    }
    return page?.value?.product?.children[0];
}

onBeforeRouteLeave(() => {
    basketStore.detailPage = false;
});

const availability = computed(() => {
    if (page?.value?.product?.structure === 'standalone') {
        return availabilities.value[0];
    }
    const child = getSelectedChild();
    return availabilities.value.find((a) => a.id === child.id);
});

const num_available = computed(() => {
    return (
        availability.value?.num_available -
            basketStore.overallQuantityForProduct(productId.value) ||
        availability.value?.num_available ||
        999
    );
});

const availableToBuy = computed(() => {
    return availability.value?.is_available_to_buy;
});

const availabilityMessage = computed(() => {
    return availability.value?.message;
});

const price = computed(() => {
    if (!availabilityLoaded.value) {
        if (page?.value?.product?.structure === 'standalone') {
            return page.value.product.price;
        }
        try {
            return getSelectedChild().price;
        } catch (error) {
            return null;
        }
    } else {
        if (page?.value?.product?.structure === 'standalone') {
            return availabilities.value[0];
        } else {
            return availabilities.value.filter(
                (a) => a.id === selectedVariant.value,
            )[0];
        }
    }
});

const canBeAdded = computed(() => {
    if (loading.value) {
        return false;
    }
    if (!availabilityLoaded.value) {
        return true;
    }
    if (page?.value?.product?.structure === 'standalone') {
        return quantity.value > 0 && availabilityLoaded.value;
    }
    return (
        selectedVariant.value &&
        num_available.value > 0 &&
        availabilityLoaded.value &&
        availableToBuy.value &&
        !maxReached.value
    );
});

const product = computed(() => {
    if (page?.value?.product?.structure === 'standalone') {
        return page?.value?.product;
    }
    return getSelectedChild();
});

const productId = computed(() => {
    return product?.value?.id;
});

const maxReached = computed(() => {
    return (
        basketStore.overallQuantityForProduct(productId.value) >=
        availability.value?.num_available
    );
});

function addToBasket() {
    loading.value = true;
    basketStore
        .addToBasket(
            productId.value,
            quantity.value,
            product.value,
            price.value,
            'common',
        )
        .then((res) => {
            loading.value = false;
            useTrackAddToCart(
                product.value,
                price.value,
                'product',
                quantity.value,
                page?.value?.breadcrumbs?.length
                    ? page?.value?.breadcrumbs[
                          page?.value?.breadcrumbs.length - 2
                      ].text
                    : '',
            );
        })
        .catch((err) => {
            console.log(err);
            loading.value = false;
            messages.value.push(t('basket.addError'));
            basketStore.removeFakeLines();
        });
}

watch(maxReached, (newVal) => {
    if (newVal && availability.value.num_available > 0) {
        messages.value.push(t('errors.maxReached'));
    } else {
        var index = messages.value.indexOf(t('errors.maxReached'));
        if (index !== -1) {
            messages.value.splice(index, 1);
        }
    }
});

onMounted(() => {
    clearTimeout(basketStore.cartModalTimeout);
    quantity.value = 1;
    basketStore.setCartModal('detail', 'message', false);
    basketStore.setCartModal('detail', 'show', false);
    basketStore.setCartModal('detail', 'product', page.value.product);
    basketStore.detailPage = true;
    recipePage.value = page?.value?.magazine_content?.load_more?.page;
    let observer;

    let options = {
        root: null,
        rootMargin: '0px',
        delay: 50,
        threshold: 1,
    };

    basketStore.setCartModal('detail', 'show', true);
    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(intersector.value);
});

watch(availabilityLoaded, () => {
    if (page?.value?.product?.structure === 'parent') {
        availabilities.value.forEach((a) => {
            if (!a.is_available_to_buy) {
                const idx = page?.value?.product?.children.findIndex(
                    (c) => c.id === a.id,
                );
                if (idx !== -1) {
                    page.value.product.children[idx].disabled = true;
                }
            }
        });
    }
});

// structured data, seo
if (!page?.value?.meta?.seo_title) {
    useHead({
        title: cleanMetaString(page?.value?.product?.title + t('detail.title')),
        meta: [
            {
                property: 'og:title',
                content: cleanMetaString(
                    page?.value?.product?.title + t('detail.title'),
                ),
            },
        ],
    });
}

const img = useImage();
const openGraphImageUrl = ref('');

if (page?.value?.open_graph_image_url) {
    openGraphImageUrl.value = img(page?.value?.open_graph_image_url, {
        width: 1200,
        height: 630,
        format: 'jpeg',
    });
} else {
    openGraphImageUrl.value = img(page?.value?.product?.images[0]?.src, {
        width: 1200,
        height: 630,
        format: 'jpeg',
    });
}
useHead({
    meta: [
        {
            property: 'og:image',
            content: openGraphImageUrl.value,
        },
    ],
});

if (!page?.value?.meta?.search_description) {
    useHead({
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: cleanMetaString(
                    product?.value?.title + t('detail.meta'),
                ),
            },
            {
                property: 'og:description',
                content: cleanMetaString(
                    product?.value?.title + t('detail.meta'),
                ),
            },
        ],
    });
}
useStructuredProduct(
    page?.value?.product,
    price.value,
    new_availabilities.data,
);
// end structured data
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.chart-float {
    z-index: 100;
    position: sticky;
    bottom: 100px;
}

.detail {
    &__image-column {
        @include media-breakpoint-down(800) {
            min-height: unset;
        }
    }

    &__product {
        margin-bottom: clamp(140px, 2.78vw + 131.11px, 180px);
    }
}

.image-slider {
    position: sticky;
    top: 110px;

    :deep(.product-image__labels) {
        padding: 15px;

        img {
            height: clamp(30px, 2.43vw + 22.22px, 65px);
        }
    }

    :deep(.product-image__organism) {
        padding: 15px;

        img {
            max-height: clamp(60px, 4.17vw + 46.67px, 120px);
            max-width: clamp(60px, 4.17vw + 46.67px, 120px);
        }
    }

    :deep(.product-image__image) {
        max-height: clamp(500px, 433px + 20.833333333333336vw, 600px);
        min-height: clamp(380px, 353px + 8.333333333333332vw, 420px);
        height: calc(100vh - 220px);

        @media (min-width: 800px) {
            max-height: clamp(500px, 266px + 29.166666666666668vw, 780px);
            min-height: clamp(380px, 346px + 4.166666666666666vw, 420px);
        }
    }

    :deep(.slider__slide) {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    @include media-breakpoint-down(800) {
        position: relative;
        top: unset;
        margin-bottom: 25px;

        :deep(.slider) {
            margin-left: $padding-container-negative;
            margin-right: $padding-container-negative;
        }

        :deep(.slider__navigation) {
            margin-left: (-$padding-container-negative);
            margin-right: (-$padding-container-negative);
        }
    }

    hr {
        color: $color-dark-01;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.breadcrumbs {
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-down(800) {
        margin-top: 0;
        margin-bottom: 50px;
    }
}

.product {
    &__category {
        color: $color-disabled;
        margin-bottom: 14px;
    }

    &__info {
        color: $color-disabled;
    }
}

.form {
    &__heading {
        color: $color-disabled;
        margin-bottom: 10px;
    }

    &__quantity-error {
        margin-top: 20px;
        width: fit-content;
    }

    &__price-original {
        color: $color-disabled;
        text-decoration: line-through;
        margin-bottom: 10px;
    }

    &__price {
        margin-bottom: 20px;

        &--offer {
            color: #c62424;
        }
    }
}

.cart {
    position: sticky;
    bottom: 15px;
}
@include media-breakpoint-down(800) {
    .image-slider--single {
        margin-left: -25px;
        margin-right: -25px;
    }
}
</style>
